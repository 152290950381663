/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
(window as any).global = window;

import 'hammerjs/hammer';
import 'zone.js';

import { ZEF } from '@shared/models/zef.model';
import { ZefThemePalette } from '@shared/models/color.model';

declare global {
  interface Window {
    ZEF?: ZEF;
    global: Window;
    HubSpotConversations: any;
    hsConversationsSettings: any;
    hsConversationsOnReady: any;
    $localize: {
      locale: string;
    };
  }
}

declare module '@angular/material/core' {
  interface CanColor {
    // @ts-expect-error This extends the color property on material components
    color: ZefThemePalette;
  }
}
